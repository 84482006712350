<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/jishu/ziliao_banner'+($i18n.locale == 'zh' ? '' : '_'+$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            
            <el-tabs v-model="activeName" type="card" style="margin-top:30px">
                
                <el-tab-pane :label="$t('ziliao.applicationTool')" name="first">
                    <el-table
                        :data="tableData"
                        :row-style="{fontSize:'14px',color:'#333',fontFamily:'SourceHanSansCN-Regular',fontWeight: '400'}"
                        :header-cell-style="{'color':'#333','border-top':'solid 1px #0052D9','background': '#EEEEEE','fontWeight': 'bold'}"
                        header-align='center'
                        style="width: 100%">
                        <el-table-column
                            prop="name"
                            :label="$t('ziliao.name')">
                        </el-table-column>
                        <el-table-column
                            prop="desc"
                            :label="$t('ziliao.desc')">
                        </el-table-column>
                        <el-table-column :label="$t('ziliao.dataDownload')" width="200">
                            <template slot-scope="scope">
                                <div style="display:flex">
                                    <div style="cursor:pointer;color:#768DAE;margin-right:10px"  @click="xiazai(scope.row.cn_url)">{{scope.row.cn_name}}</div>
                                    <div style="cursor:pointer;color:#768DAE;margin-right:10px"  @click="xiazai(scope.row.en_url)" v-if="scope.row.en_name != ''">{{scope.row.en_name}}</div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane :label="$t('ziliao.ziliaoDownload')" name="second">
                    <div>
                        <el-input :placeholder="$t('ziliao.searchhint')" v-model="downloadParams.key" @keyup.enter.native="search">
                            <template slot="prepend"><el-button slot="append" icon="el-icon-search"></el-button></template>
                            <el-button slot="append" style="background: #0052D9;color:#ffffff;" @click="search">{{$t('inquire')}}</el-button>
                        </el-input>
                    </div>
                    <div style="margin-top:1%">
                        <el-table
                            :row-style="{fontSize:'14px',color:'#333',fontFamily:'SourceHanSansCN-Regular',fontWeight: '400'}"
                            :header-cell-style="{'color':'#333','border-top':'solid 1px #0052D9','background': '#EEEEEE','fontWeight': 'bold'}"
                            :data="list"
                            header-align='center'
                            style="width: 100%">
                            <el-table-column prop="product_name" :label="$t('ziliao.model')" ></el-table-column>
                            <el-table-column prop="name"  :label="$t('manualInChinese')" align="center">
                                <template slot-scope="scope" v-if="scope.row.download[0]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="chakan(scope.row.download[0].down_value,0)">{{scope.row.download[0].version}}<span style="margin-left:15px" @click.stop="xiazai(scope.row.download[0].down_value)">{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name"  :label="$t('englishManual')" align="center">
                                <template slot-scope="scope" v-if="scope.row.download[1]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="chakan(scope.row.download[1].down_value,1)">{{scope.row.download[1].version}}<span style="margin-left:15px" @click.stop="xiazai(scope.row.download[1].down_value)">{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name"  :label="$t('testingSoftware')" align="center">
                                <template slot-scope="scope" v-if="scope.row.download[2]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="chakan(scope.row.download[2].down_value,2)">{{scope.row.download[2].version}} <span style="margin-left:15px" @click.stop="xiazai(scope.row.download[2].down_value)">{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name"  :label="$t('DModelDiagram')" align="center">
                                <template slot-scope="scope" v-if="scope.row.download[4]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="xiazai(scope.row.download[4].down_value,4)">{{$t('download')}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" :label="$t('chineseCommunicationProtocol')" align="center">
                                <template slot-scope="scope" v-if="scope.row.download[10]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="xiazai(scope.row.download[10].down_value,10)"><span>{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" :label="$t('englishCommunicationProtocol')" align="center">
                                <template slot-scope="scope" v-if="scope.row.download[11]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="xiazai(scope.row.download[11].down_value,11)"><span>{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" :label="$t('ProductSinglePage')" align="center">
                                <template slot-scope="scope" v-if="scope.row.download[7]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="xiazai(scope.row.download[7].down_value,7)"><span>{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination
                        v-show="total>10"
                        style="margin-top:3%;text-align:center"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="downloadParams.page"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="downloadParams.limit"
                        layout="total, prev, pager, next"
                        :pager-count="5"
                        :total="total">
                    </el-pagination>
                </el-tab-pane>

                <el-tab-pane :label="$t('ziliao.offdataDownload')" name="xiajia">
                    <div>
                        <el-input :placeholder="$t('ziliao.searchoffproduct')" v-model="offdownloadParams.key" @keyup.enter.native="offsearch">
                            <template slot="prepend"><el-button slot="append" icon="el-icon-search"></el-button></template>
                            <el-button slot="append" style="background: #0052D9;color:#ffffff;" @click="offsearch">{{$t('inquire')}}</el-button>
                        </el-input>
                    </div>
                    <div style="margin-top:1%">
                        <el-table
                            :row-style="{fontSize:'14px',color:'#333',fontFamily:'SourceHanSansCN-Regular',fontWeight: '400'}"
                            :header-cell-style="{'color':'#333','border-top':'solid 1px #0052D9','background': '#EEEEEE','fontWeight': 'bold'}"
                            :data="offlist"
                            header-align='center'
                            style="width: 100%">
                            <el-table-column prop="product_name" :label="$t('ziliao.model')" ></el-table-column>
                            <el-table-column prop="name"  :label="$t('manualInChinese')" >
                                <template slot-scope="scope" v-if="scope.row.download[0]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="chakan(scope.row.download[0].down_value,0)">{{scope.row.download[0].version}}<span style="margin-left:15px" @click.stop="xiazai(scope.row.download[0].down_value)">{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name"  :label="$t('englishManual')">
                                <template slot-scope="scope" v-if="scope.row.download[1]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="chakan(scope.row.download[1].down_value,1)">{{scope.row.download[1].version}}<span style="margin-left:15px" @click.stop="xiazai(scope.row.download[1].down_value)">{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name"  :label="$t('testingSoftware')">
                                <template slot-scope="scope" v-if="scope.row.download[2]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="chakan(scope.row.download[2].down_value,2)">{{scope.row.download[2].version}} <span style="margin-left:15px" @click.stop="xiazai(scope.row.download[2].down_value)">{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name"  :label="$t('DModelDiagram')">
                                <template slot-scope="scope" v-if="scope.row.download[4]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="xiazai(scope.row.download[4].down_value,4)">{{$t('download')}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" :label="$t('chineseCommunicationProtocol')">
                                <template slot-scope="scope" v-if="scope.row.download[10]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="xiazai(scope.row.download[10].down_value,10)"><span>{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" :label="$t('englishCommunicationProtocol')">
                                <template slot-scope="scope" v-if="scope.row.download[11]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="xiazai(scope.row.download[11].down_value,11)"><span>{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" :label="$t('ProductSinglePage')">
                                <template slot-scope="scope" v-if="scope.row.download[12]">
                                    <div style="color:#768DAE; cursor: pointer;" @click="xiazai(scope.row.download[12].down_value,12)"><span>{{$t('download')}}</span></div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination
                        v-show="offtotal>10"
                        style="margin-top:3%;text-align:center"
                        @size-change="offhandleSizeChange"
                        @current-change="offhandleCurrentChange"
                        :current-page="offdownloadParams.page"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="offdownloadParams.limit"
                        layout="total, prev, pager, next"
                        :pager-count="5"
                        :total="offtotal">
                    </el-pagination>
                </el-tab-pane>

                <el-tab-pane :label="$t('ziliao.efiDrawings')" name="tuzhi" v-if="$i18n.locale == 'zh'">
                    <div>
                        <el-input placeholder="" v-model="tuzhiParams.key" @keyup.enter.native="tuzhisearch">
                            <template slot="prepend"><el-button slot="append" icon="el-icon-search"></el-button></template>
                            <el-button slot="append" style="background: #0052D9;color:#ffffff;" @click="tuzhisearch">{{$t('inquire')}}</el-button>
                        </el-input>
                    </div>
                    <div style="margin-top:10px">
                        <el-collapse :accordion="true">
                            <el-collapse-item v-for="(item,index) in tuzhilist" :key="index" :title="item.product_name">
                                <div>
                                    <div class="tuzhiitem" v-for="(items,indexs) in item.get_download_tuzhi" :key="indexs">
                                        <div>{{items.name}}</div>
                                        <div style="color:#768DAE; cursor: pointer;" @click="xiazai(items.down_value)"><span>{{$t('download')}}</span></div>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                        <el-pagination
                            v-show="tuzhitotal>10"
                            style="margin-top:3%;text-align:center"
                            @size-change="tuzhiSizeChange"
                            @current-change="tuzhiCurrentChange"
                            :current-page="tuzhiParams.page"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="tuzhiParams.limit"
                            layout="total, prev, pager, next"
                            :pager-count="5"
                            :total="tuzhitotal">
                        </el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="$t('layout.model')" name="xuanxing" v-if="$i18n.locale == 'en'">
                    <el-table
                        :data="xuanxinglist"
                        :row-style="{fontSize:'14px',color:'#333',fontFamily:'SourceHanSansCN-Regular',fontWeight: '400'}"
                        :header-cell-style="{'color':'#333','border-top':'solid 1px #0052D9','background': '#EEEEEE','fontWeight': 'bold'}"
                        header-align='center'
                        style="width: 100%">
                        <el-table-column
                            prop="catalogue_title"
                            :label="$t('ziliao.name')">
                        </el-table-column>
                        <el-table-column :label="$t('ziliao.dataDownload')" width="200">
                            <template slot-scope="scope">
                                <div style="display:flex">
                                    <div style="cursor:pointer;color:#768DAE;margin-right:10px"  @click="xiazai(scope.row.catalogue_img)">{{$t('download')}}</div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import { downloadList,tuzhiList,toolList,catalogueList,offdownloadList} from "@/api/api"; 
export default {
    data(){
        return{
            downloadParams:{
                page:1,
                limit:50
            },
            offdownloadParams:{
                page:1,
                limit:50
            },
            tuzhiParams:{
                page:1,
                limit:10
            },
            offtotal:0,
            offlist:[],
            tuzhitotal:0,
            tuzhilist:[],
            total:0,
            list:[],
            activeName:'first',
            tableData: [],
            title: '',
            xuanxinglist:[]
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    created(){
        this.title = this.$t('layout.download')+'-'+this.$t('seo.title')
        this.getToolList()
        this.getList()
        this.getoffList()
        this.getTuzhiList()
        if (this.$i18n.locale == 'en') {
            this.getxuanxinglist()
        }
        console.log(this.$i18n.locale);
    },
    methods:{
        async getToolList(){
            const {data:res} = await toolList()
            this.tableData = res
            console.log(this.tableData);
        },
        async getList(){
            const {data:res} = await downloadList(this.downloadParams)
            this.total = res.total
            this.list = res.list
        },
        async getoffList(){
            const {data:res} = await offdownloadList(this.offdownloadParams)
            this.offtotal = res.total
            this.offlist = res.list
        },
        async getTuzhiList(){
            const {data:res} = await tuzhiList(this.tuzhiParams)
            this.tuzhitotal = res.total
            this.tuzhilist = res.list
        },
        async getxuanxinglist() {
            const{data:res} = await catalogueList()
            this.xuanxinglist = res
        },
        handleSizeChange(value){
            this.downloadParams.limit = value
            this.getList()
        },
        handleCurrentChange(value){
            this.downloadParams.page = value
            this.getList()
        },
        offhandleSizeChange(value){
            this.offdownloadParams.limit = value
            this.getoffList()
        },
        offhandleCurrentChange(value){
            this.offdownloadParams.page = value
            this.getoffList()
        },
        tuzhiSizeChange(value){
            this.tuzhiParams.limit = value
            this.getTuzhiList()
        },
        tuzhiCurrentChange(value){
            this.tuzhiParams.page = value
            this.getTuzhiList()
        },
        xiazai(data,type){
            let userinfo = sessionStorage.getItem('userinfo') ? sessionStorage.getItem('userinfo') : ''
            if(userinfo == ''){
                if(type == 4 || type == 10 || type == 11){
                    this.$confirm(this.$t('loginerror'), this.$t('notLogIn'), {
                    confirmButtonText: this.$t('logIn'),
                    cancelButtonText: this.$t('close'),
                    customClass: 'myConfirm',
                    type: 'warning'
                    }).then(() => {
                        this.$router.push('/login')
                    }).catch(() => {
                    });
                }else{
                    let url = process.env.VUE_APP_BASE_API+data
                    window.open(url)
                }
            }else{
                let url = process.env.VUE_APP_BASE_API+data
                window.open(url)
            }
        },
        chakan(data){
            let url = process.env.VUE_APP_BASE_API+data
            window.open(url)
        },
        search(){
            this.getList()
        },
        offsearch(){
            this.getoffList()
        },
        tuzhisearch(){
            this.getTuzhiList()
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 60%;
        margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
    }
    
</style>
<style >
.btnFalses{
    background: #0052D9 !important;
}
.btnFalse:hover{
    background-color: transparent !important;
}
.btnFalse:focus{
    background-color: transparent !important;
}
.myConfirm{
    width: 350px !important;
}
.tuzhiitem{
    display:flex;
    justify-content:space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(235, 238, 245);
    margin-bottom: 10px;
}
</style >
<style scoped>
    /deep/ .el-pager li.active{
        color:#0052D9;
    }
    /deep/ .el-pager li.active{
        color:#0052D9;
    }
    /deep/ .el-pager li:hover{
        color:#0052D9;
    }
    /deep/ .el-tabs__item{
        font-size: 16px;
        font-weight: bold;
        width: 250px;
        height: 60px;
        line-height: 60px;
        text-align: center;
    }
    /deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav{
        border: none;
    }
    /deep/ .el-tabs--card>.el-tabs__header{
        border: none;
    }
    /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
        border-left:none;
    }
    /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        border-bottom-color : transparent;
        background: #0052D9;
        color: #fff;
    }
    /deep/ .el-tabs__item:hover{
        color: #0052D9;
    }
    /deep/ .el-collapse-item__header{
        padding-left: 20px;
        font-weight: bold;
        font-size: 15px;
    }
    /deep/ .el-collapse-item__content{
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom:10px
    }
</style>